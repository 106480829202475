$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$light-grey-mobile: #d8d8d8;

$stars: multiple-box-shadow(100);

.animate-turn-off {
  animation: turn-off 0.55s $ease-out-quint;
}

.animate-turn-on {
  animation: turn-on 3s linear;
}

.animate-overlay-on {
  animation: overlay-anim 5s linear;
}

body {
  margin: 0;
  overflow: hidden;
}

button {
  border: 0;
  padding: 0;
  background: none;
  font-size: 100%;
  font-family: inherit;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:focus {
    outline: 4px solid white !important;
    background: $light-grey-mobile;
    color: black;
  }
}

#green {
  color: #39620a;
}

#yellow {
  color: #f4eb75;
}

#red {
  color: #f45555;
}

#blue {
  color: #395cc9;
}

.plvtform {
  &-content {
    width: 100%;
    height: 100%;
  }

  &-tube {
    &-border {
      width: 100%;
      height: 100%;
      background-image: url(../src/images/border-stretched.png);
      background-size: 100% 100%;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      pointer-events: none;
      @include mobile {
        display: none;
        height: 100%;
        width: 100%;
      }

      &-mobile {
        @include mobile {
          display: inherit;
          background-image: url('../src/images/border-compressed.png');
        }
      }
    }

    &-shape {
      background: black;
      color: white;
      text-align: center;
      overflow: hidden;
      height: 100%;
      width: 100%;
      //filter: contrast(140%);
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(18, 16, 16, 0.1);
        opacity: 0;
        z-index: 7;
        pointer-events: none;
        animation: flicker 0.15s infinite;
      }
      // scanlines
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(
            rgba(18, 16, 16, 0) 50%,
            rgba(0, 0, 0, 0.25) 50%
          ),
          linear-gradient(
            90deg,
            rgba(255, 0, 0, 0.06),
            rgba(0, 255, 0, 0.02),
            rgba(0, 0, 255, 0.06)
          );
        z-index: 7;
        background-size: 100% 2px, 3px 100%;
        pointer-events: none;
      }
    }
  }

  &-copyright {
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    @include mobile {
      bottom: 5px;
    }

    &-text {
      @include press-start;
      font-size: 0.7em;
      line-height: 1.5em;
      width: 100vw;
      margin: 0;
      @include mobile {
        line-height: 1.2em;
        font-size: 0.6em;
      }
    }
  }

  &-stars {
    box-shadow: $stars;
    position: absolute;
    width: 3px;
    height: 3px;
    background: transparent;
    animation: animStar 120s linear infinite;
    /*
        filter: blur(1px);
        -webkit-transform: translateZ(0);
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        */
    // &:after {
    //   content: " ";
    //   position: absolute;
    //   top: 2000px;
    //   width: 3px;
    //   height: 3px;
    //   box-shadow: $stars;
    //   background: transparent;
    // }
  }

  &-space {
    &-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &-container {
      width: 100%;
      height: 100%;
    }

    &-option {
      @include press-start;
      font-weight: normal;
      cursor: pointer;
      @include no-select;
    }

    &-header {
      @include press-start;
      font-weight: normal;
      @include no-select;
    }

    &-text {
      @include press-start;
      font-weight: normal;
      line-height: 1.4em;
      @include no-select;
    }
  }

  &-home {
    position: absolute;
    top: 10vh;
    left: 10vw;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 2;

    & > img {
      width: 100%;
      height: auto;
    }
    @include mobile {
      display: none;
    }
  }

  &-tc {
    cursor: pointer;

    &:hover {
      color: #d8d8d8;
    }
  }
}

#plvtform-back {
  @include mobile {
    display: initial !important;
  }
}
