$dark-grey-desktop: #a29f9f;
$light-grey-desktop: #e6e2e2;
$navbar-grey-desktop: #d4cdcd;
$home-accent-desktop: #7d7d7d;
$dark-grey-mobile: #e6e2e2;
$light-grey-mobile: #d8d8d8;
$screen-background: #121010;
$contact-dark-grey: #9b9b9b;
$contact-light-grey: #d7d6d6;
$contact-button-grey: #999999;
$contact-second-grey: #dddddd;
$contact-send-grey: #545454;
$mobile-breakpoint: 320px;
$mobile-max-width: 480px;
$mobile-max-w: 480px;

$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
@font-face {
  font-family: 'Press Start 2P';
  src: url('../fonts/PressStart2P-Regular.ttf');
  src: url('../fonts/PressStart2P-Regular.ttf') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@import 'partials/mixins';
@import 'partials/animations';
@import 'partials/global';
@import 'partials/home';
@import 'partials/landing';
@import 'partials/contact';

@font-face {
  font-family: 'Press Start 2P';
  src: local('Press Start 2P'),
    url(../fonts/PressStart2P-Regular.ttf) format('ttf');
  font-weight: normal;
  font-style: normal;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes flicker {
  0% {
    opacity: 0.0209899495;
  }

  5% {
    opacity: 0.5937149178;
  }

  10% {
    opacity: 0.4926037699;
  }

  15% {
    opacity: 0.1439182522;
  }

  20% {
    opacity: 0.0554656186;
  }

  25% {
    opacity: 0.5635130083;
  }

  30% {
    opacity: 0.5355750431;
  }

  35% {
    opacity: 0.5929411122;
  }

  40% {
    opacity: 0.2308273491;
  }

  45% {
    opacity: 0.574027448;
  }

  50% {
    opacity: 0.152002878;
  }

  55% {
    opacity: 0.1080741508;
  }

  60% {
    opacity: 0.6028456274;
  }

  65% {
    opacity: 0.29829011;
  }

  70% {
    opacity: 0.8161914381;
  }

  75% {
    opacity: 0.4096207565;
  }

  80% {
    opacity: 0.915251055;
  }

  85% {
    opacity: 0.738746315;
  }

  90% {
    opacity: 0.4390701238;
  }

  95% {
    opacity: 0.4140878504;
  }

  100% {
    opacity: 0.7934301875;
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(36.4402530873px, 9999px, 35.3828167898px, 0);
  }

  5% {
    clip: rect(97.0342874391px, 9999px, 59.5754293025px, 0);
  }

  10% {
    clip: rect(71.6852646046px, 9999px, 4.3018238626px, 0);
  }

  15% {
    clip: rect(12.5628663536px, 9999px, 70.9303214562px, 0);
  }

  20% {
    clip: rect(91.8063461191px, 9999px, 82.8102177672px, 0);
  }

  25% {
    clip: rect(81.0593005029px, 9999px, 91.8381210137px, 0);
  }

  30% {
    clip: rect(8.0136083117px, 9999px, 41.7351553826px, 0);
  }

  35% {
    clip: rect(40.7717953531px, 9999px, 9.417129805px, 0);
  }

  40% {
    clip: rect(93.5619667171px, 9999px, 71.2573320583px, 0);
  }

  45% {
    clip: rect(86.9630876608px, 9999px, 45.5123437703px, 0);
  }

  50% {
    clip: rect(74.3457326872px, 9999px, 99.892773731px, 0);
  }

  55% {
    clip: rect(14.5410346252px, 9999px, 12.0249005167px, 0);
  }

  60% {
    clip: rect(3.5577604547px, 9999px, 40.3675453892px, 0);
  }

  65% {
    clip: rect(84.0534058112px, 9999px, 39.346418751px, 0);
  }

  70% {
    clip: rect(79.5396264658px, 9999px, 21.220953458px, 0);
  }

  75% {
    clip: rect(55.252807622px, 9999px, 16.6838594517px, 0);
  }

  80% {
    clip: rect(40.1015014124px, 9999px, 1.4071143944px, 0);
  }

  85% {
    clip: rect(27.7438298895px, 9999px, 25.4376652423px, 0);
  }

  90% {
    clip: rect(14.0140789548px, 9999px, 61.6797208365px, 0);
  }

  95% {
    clip: rect(56.9941662197px, 9999px, 26.7082248055px, 0);
  }

  100% {
    clip: rect(47.1797308652px, 9999px, 56.0013377932px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(13.8906339223px, 9999px, 66.8166760295px, 0);
  }

  5% {
    clip: rect(21.7341273616px, 9999px, 64.1243960955px, 0);
  }

  10% {
    clip: rect(43.2225822088px, 9999px, 91.4813414565px, 0);
  }

  15% {
    clip: rect(52.2946239116px, 9999px, 33.539071409px, 0);
  }

  20% {
    clip: rect(10.2952455332px, 9999px, 58.778856415px, 0);
  }

  25% {
    clip: rect(20.7164612211px, 9999px, 19.8667474867px, 0);
  }

  30% {
    clip: rect(20.4653287174px, 9999px, 57.2433540694px, 0);
  }

  35% {
    clip: rect(31.1029542908px, 9999px, 90.8367897206px, 0);
  }

  40% {
    clip: rect(66.5782696286px, 9999px, 83.519453891px, 0);
  }

  45% {
    clip: rect(16.0443227122px, 9999px, 16.0175542283px, 0);
  }

  50% {
    clip: rect(4.2989378364px, 9999px, 59.5411745098px, 0);
  }

  55% {
    clip: rect(69.8858991974px, 9999px, 35.7779674734px, 0);
  }

  60% {
    clip: rect(78.8790837195px, 9999px, 65.2329469344px, 0);
  }

  65% {
    clip: rect(28.3814687153px, 9999px, 85.0314047175px, 0);
  }

  70% {
    clip: rect(86.0901749406px, 9999px, 65.5148224203px, 0);
  }

  75% {
    clip: rect(70.0078223329px, 9999px, 75.7338861672px, 0);
  }

  80% {
    clip: rect(19.4073946896px, 9999px, 68.6098977884px, 0);
  }

  85% {
    clip: rect(14.0450089235px, 9999px, 59.1770303986px, 0);
  }

  90% {
    clip: rect(47.2481584406px, 9999px, 2.9061068305px, 0);
  }

  95% {
    clip: rect(83.629067688px, 9999px, 68.1397258813px, 0);
  }

  100% {
    clip: rect(87.2530895855px, 9999px, 11.5442922637px, 0);
  }
}

@keyframes turn-on-quick {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 1;
  }

  19.1% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  19.7% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  49.35% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 0;
  }

  60% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0) saturate(1);
    -webkit-filter: contrast(0) brightness(0) saturate(1);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@-webkit-keyframes turn-on-quick {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 1;
  }

  19.1% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  19.7% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  49.35% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 0;
  }

  60% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0) saturate(1);
    -webkit-filter: contrast(0) brightness(0) saturate(1);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0) saturate(1);
    -webkit-filter: contrast(0) brightness(0) saturate(1);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@-webkit-keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0) saturate(1);
    -webkit-filter: contrast(0) brightness(0) saturate(1);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }

  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

@-webkit-keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }

  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

@keyframes turn-on-quick-safari {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    opacity: 1;
  }

  19.1% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  19.7% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  49.35% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    opacity: 0;
  }

  60% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@-webkit-keyframes turn-on-quick-safari {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    opacity: 1;
  }

  19.1% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  19.7% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  49.35% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    opacity: 0;
  }

  60% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@keyframes turn-on-safari {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@-webkit-keyframes turn-on-safari {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
  }
}

@keyframes turn-off-safari {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    opacity: 1;
  }

  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
  }

  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

@-webkit-keyframes turn-off-safari {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    opacity: 1;
  }

  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
  }

  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  21% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@-webkit-keyframes animStar {
  0% {
    -webkit-transform: translateY(0px) translate3d(0, 0, 0) translateZ(0);
    transform: translateY(0px) translate3d(0, 0, 0) translateZ(0);
  }

  100% {
    -webkit-transform: translateY(-2000px) translate3d(0, 0, 0) translateZ(0);
    transform: translateY(-2000px) translate3d(0, 0, 0) translateZ(0);
  }
}

@keyframes animStar {
  0% {
    -webkit-transform: translateY(0px) translate3d(0, 0, 0) translateZ(0);
    transform: translateY(0px) translate3d(0, 0, 0) translateZ(0);
  }

  100% {
    -webkit-transform: translateY(-2000px) translate3d(0, 0, 0) translateZ(0);
    transform: translateY(-2000px) translate3d(0, 0, 0) translateZ(0);
  }
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  21% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0 82%;
  }

  50% {
    background-position: 100% 19%;
  }

  100% {
    background-position: 0 82%;
  }
}

@keyframes light {
  100% {
    transform: translate3d(50%, 50%, 0);
  }
}

.animate-turn-off {
  animation: turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1);
}

.animate-turn-on {
  animation: turn-on 3s linear;
}

.animate-overlay-on {
  animation: overlay-anim 5s linear;
}

body {
  margin: 0;
  overflow: hidden;
}

button {
  border: 0;
  padding: 0;
  background: none;
  font-size: 100%;
  font-family: inherit;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

button:focus {
  outline: 4px solid #fff !important;
  background: #d8d8d8;
  color: #000;
}

#green {
  color: #39620a;
}

#yellow {
  color: #f4eb75;
}

#red {
  color: #f45555;
}

#blue {
  color: #395cc9;
}

.plvtform-content {
  width: 100%;
  height: 100%;
}

.plvtform-tube-border {
  width: 100%;
  height: 100%;
  background-image: url(./images/border-stretched.png);
  background-size: 100% 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  pointer-events: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .plvtform-tube-border {
    display: none;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .plvtform-tube-border-mobile {
    display: inherit;
    background-image: url('../src/images/border-compressed.png');
  }
}

.plvtform-tube-shape {
  background: #000;
  color: #fff;
  text-align: center;
  overflow: hidden;
  height: 91vh;
  width: 100%;
}

.plvtform-tube-shape::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 7;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

.plvtform-tube-shape::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 7;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .plvtform-copyright {
    bottom: 5px;
  }
}

.plvtform-copyright-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.7em;
  line-height: 1.5em;
  width: 100vw;
  margin: 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .plvtform-copyright-text {
    line-height: 1.2em;
    font-size: 0.6em;
  }
}

.plvtform-stars {
  box-shadow: 1487px 1841px #fff, 1667px 494px #fff, 1672px 582px #fff,
    846px 1305px #fff, 1713px 648px #fff, 1727px 54px #fff, 1932px 618px #fff,
    1361px 83px #fff, 1644px 1346px #fff, 484px 1966px #fff, 1038px 44px #fff,
    1048px 165px #fff, 1612px 405px #fff, 1520px 899px #fff, 466px 1559px #fff,
    30px 1448px #fff, 1265px 926px #fff, 1310px 105px #fff, 1681px 1942px #fff,
    537px 1551px #fff, 1584px 1150px #fff, 723px 1979px #fff, 1392px 956px #fff,
    1575px 1152px #fff, 213px 1153px #fff, 214px 288px #fff, 1807px 1299px #fff,
    1053px 150px #fff, 1422px 1530px #fff, 455px 1178px #fff, 1775px 1437px #fff,
    163px 1638px #fff, 1744px 1385px #fff, 1633px 579px #fff, 881px 1958px #fff,
    363px 1893px #fff, 496px 719px #fff, 1739px 1770px #fff, 656px 1376px #fff,
    197px 325px #fff, 845px 723px #fff, 1676px 863px #fff, 1501px 1723px #fff,
    117px 815px #fff, 1164px 1321px #fff, 1592px 461px #fff, 1591px 1320px #fff,
    1476px 984px #fff, 1839px 1113px #fff, 1322px 1258px #fff, 542px 1207px #fff,
    1560px 481px #fff, 913px 1251px #fff, 226px 1770px #fff, 655px 227px #fff,
    299px 35px #fff, 1521px 1367px #fff, 1022px 1796px #fff, 1102px 1732px #fff,
    1082px 1149px #fff, 101px 779px #fff, 1018px 610px #fff, 21px 1012px #fff,
    1218px 1190px #fff, 545px 1001px #fff, 188px 425px #fff, 738px 1880px #fff,
    1837px 1661px #fff, 192px 83px #fff, 1016px 981px #fff, 771px 463px #fff,
    831px 122px #fff, 1080px 1949px #fff, 1909px 687px #fff, 760px 1954px #fff,
    1708px 1548px #fff, 1065px 1538px #fff, 1901px 792px #fff, 475px 543px #fff,
    1736px 204px #fff, 1255px 1173px #fff, 805px 1994px #fff, 1125px 1318px #fff,
    297px 1713px #fff, 1512px 844px #fff, 299px 1662px #fff, 1188px 325px #fff,
    226px 1532px #fff, 1214px 222px #fff, 1201px 783px #fff, 1503px 1412px #fff,
    1909px 1188px #fff, 1789px 751px #fff, 1783px 644px #fff, 1325px 614px #fff,
    1271px 1440px #fff, 554px 665px #fff, 276px 213px #fff, 859px 1574px #fff,
    1001px 1189px #fff;
  position: absolute;
  width: 3px;
  height: 3px;
  background: rgba(0, 0, 0, 0);
  animation: animStar 120s linear infinite;
}

.plvtform-space-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.plvtform-space-container {
  width: 100%;
  height: 100%;
}

.plvtform-space-option {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-weight: normal;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.plvtform-space-header {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-weight: normal;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.plvtform-space-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-weight: normal;
  line-height: 1.4em;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.plvtform-home {
  position: absolute;
  top: 10vh;
  left: 10vw;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 2;
}

.plvtform-home > img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .plvtform-home {
    display: none;
  }
}

.plvtform-tc {
  cursor: pointer;
}

.plvtform-tc:hover {
  color: #d8d8d8;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  #plvtform-back {
    display: initial !important;
  }
}

.about-content {
  display: flex;
  flex-direction: column;
  background: #000;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-content {
    display: none;
  }
}

.about-content-mobile {
  width: 100%;
  height: 100%;
  display: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-content-mobile {
    display: block;
  }
}

.about-background {
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-background {
    background-position-x: -400px;
    height: 100%;
    width: 100%;
  }
}

.about-blurred-border {
  width: 39vw;
  height: 40vh;
  border-radius: 15px;
  filter: blur(5px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.about-third-title-mobile {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-third-text-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(146, 146, 146, 0.72);
  padding: 10px 0;
}

.about-third-copyright-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.about-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(146, 146, 146, 0.72);
  border-radius: 15px;
  padding: 15px;
}

@media (max-height: 640px) {
  .about-container {
    transform: translateX(-50%) translateY(-50%) scale(0.7);
  }
}

.about-container-fixed {
  width: 500px;
}

.about-container-mobile {
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-navbar {
  padding: 10px 0;
  margin-bottom: 5%;
}

.about-navbar-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-weight: normal;
  color: #fff;
  font-size: 2em;
  margin: 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-navbar-text {
    font-size: 1.5em;
    background: rgba(146, 146, 146, 0.72);
    padding: 10px;
    width: 100%;
  }
}

.about-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1%;
}

.about-info-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  margin: 0;
  line-height: 1.75em;
  font-size: 0.9em;
  text-align: center;
  max-height: 45vh;
  overflow-y: auto;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-info-text {
    padding: 5px 0;
    font-size: 0.9em;
    max-height: 60vh;
    border: none;
  }
}

.about-info-text br {
  display: block;
  content: ' ';
  margin-top: 5px;
}

.about-artists {
  background-position: center;
}

.about-artists-artist {
  cursor: pointer;
}

.about-artists-artist:hover {
  opacity: 0.75;
}

.about-artist {
  background-position: center;
  filter: brightness(1.1);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-artist-content {
    display: initial;
  }
}

.about-artist-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.about-artist-container {
  padding: 10px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-artist-container {
    width: 100%;
  }
}

.about-artist-navbar-text {
  margin-bottom: 25px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-artist-navbar-text {
    background: none;
  }
}

.about-artist-image {
  border-radius: 15px;
  filter: brightness(0.5);
  width: 50vw;
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .about-artist-image {
    width: 100%;
    border-radius: 0;
  }
}

.cart-close {
  align-self: flex-start;
  font-size: 1.3em;
  text-align: left;
  margin: 0;
}

.cart-close button {
  cursor: pointer;
}

.cart-item {
  display: flex;
  justify-content: center;
  min-height: 75px;
}

.cart-item-delete-wrapper {
  flex: 0.5;
  display: flex;
  align-items: center;
}

.cart-item-name-wrapper {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-quantity-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-price-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-delete {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  font-size: 0.75em;
}

.cart-item-image {
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.cart-item-name {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.3em;
}

.cart-item-quantity {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.8em;
}

.cart-item-price {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.8em;
}

.cart-items-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.8em;
  margin-top: 20px;
}

.rainbow-text {
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 13s ease infinite;
  -z-animation: rainbow 13s ease infinite;
  -o-animation: rainbow 13s ease infinite;
  animation: rainbow 13s ease infinite;
  transform: translateZ(0);
  -webkit-background-clip: text;
  color: #9b9b9b !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.contact-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contact-contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #d7d6d6;
  padding: 20px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-contact {
    padding: 10px;
  }
}

.contact-contact-label {
  background: #999;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  width: 100%;
  padding: 10px 5px;
}

.contact-contact-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  flex: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-contact-label-container {
    display: none;
  }
}

.contact-contact-label-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
}

.contact-contact-textarea {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  min-height: 100%;
  width: 100%;
  height: 100%;
  line-height: 1.1em;
  padding: 10px;
  font-size: 0.9em;
  text-align: left;
  resize: none;
  position: relative;
  position: absolute;
  overflow-y: auto !important;
  padding-left: 45px;
}

.contact-contact-textarea:focus {
  outline: none;
}

.contact-contact-textarea::-webkit-input-placeholder {
  opacity: 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-contact-textarea::-webkit-input-placeholder {
    opacity: 1;
    text-align: center;
  }
}

.contact-contact-textarea-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  overflow-y: auto;
  position: relative;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  background: #e7e5e6;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-contact-textarea-container {
    width: 100%;
  }
}

.contact-contact-input {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  width: 100%;
  line-height: 1em;
  padding: calc(10px + 0.67em) 10px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
}

.contact-contact-input:focus {
  outline: none;
}

.contact-contact-input-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  background: #e7e5e6;
  height: 57px;
  margin: 0 10px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-contact-input-container {
    width: 100%;
    height: 3em;
  }
}

.contact-container {
  width: 40vw;
  height: 49vh;
  margin: auto;
  background: #d7d6d6;
  border: 5px solid #000;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 400px;
  min-width: 695px;
}

@media screen and (max-width: 930px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-container {
    min-height: 0;
    min-width: 0;
    width: 65%;
    margin: 15px auto 50px;
    height: 70%;
    align-self: center;
    margin-top: 100px;
  }
}

.contact-navbar {
  padding: 10px 0;
  border-bottom: 4px solid #000;
  z-index: 1;
}

.contact-navbar-decoration {
  width: 0;
  height: 0;
  position: relative;
}

.contact-navbar-svg {
  width: calc(40vw - 30px);
  height: 1.3em;
  padding: 10px 15px;
  min-width: 665px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-navbar-svg {
    width: calc(90vw - 30px);
    min-width: 0;
  }
}

.contact-navbar-line {
  stroke: #000;
  stroke-width: 3px;
}

.contact-navbar-square-inner {
  fill: #d7d6d6;
  stroke-width: 3;
  stroke: #000;
  height: 100%;
  width: 30px;
  x: 4%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-navbar-square-inner {
    x: 8%;
  }
}

.contact-navbar-square-outer {
  stroke-width: 10;
  stroke: #d7d6d6;
  height: 100%;
  width: 30px;
  x: 4%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-navbar-square-outer {
    x: 8%;
  }
}

.contact-navbar-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  margin: 0;
  font-size: 1.3em;
  color: #9b9b9b;
}

.contact-navbar-text-extra {
  background: #d7d6d6;
  padding: 5px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-navbar-text-extra {
    color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
  }
}

.contact-email-container {
  display: flex;
  flex: 1;
}

.contact-subject-container {
  display: flex;
  flex: 1;
}

.contact-message-container {
  display: flex;
  flex: 2;
}

.contact-footer {
  padding: 0;
  border-top: 2px solid #000;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-footer {
    border: none;
    justify-content: center;
    padding: 7px 5%;
  }
}

.contact-footer-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  margin: 5px;
  font-size: 1.3em;
  background: #545454;
  border-radius: 8px;
  padding: 7px;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  text-align: center;
  flex: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .contact-footer-text {
    color: 4a4a4a;
    background: rgba(0, 0, 0, 0);
    border: 4px solid #060606;
    border-radius: 0;
  }
}

::-webkit-input-placeholder {
  display: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  ::-webkit-input-placeholder {
    display: inherit;
  }
}

.home-content {
  background-image: url('./images/home-background-2.gif');
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-content {
    display: none;
  }
}

.home-content-mobile {
  display: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-content-mobile {
    display: block;
    height: 100%;
    width: 100%;
    background: #dbdbdb;
  }
}

.home-container {
  margin: auto;
  background: #e6e2e2;
  width: 55vw;
  height: 57vh;
  border: 2px solid #000;
  border-radius: 7px;
  min-height: 300px;
  min-width: 465px;
}

.home-container-mobile {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.home-video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.home-video-container {
  position: relative;
  width: 0;
  height: 0;
  margin-left: 100%;
}

.home-video-container-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}

.home-video-wrapper {
  position: absolute;
  z-index: 2;
  top: 10vh;
  left: -7.2vw;
}

.home-video-wrapper-fullscreen {
  background: #000;
  top: 0;
  left: 0;
}

.home-video-parent {
  border-radius: 4px;
  height: 25vh;
  width: 325px;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.46);
  background: #000;
  overflow: hidden;
  min-height: 130px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-video-parent {
    transform: none;
  }
}

.home-video-gif {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 60%;
  height: auto;
  cursor: pointer;
}

.home-video-gif-mobile {
  height: 100%;
  width: 100%;
}

.home-video-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: absolute;
  z-index: 2;
  font-size: 0.9em;
  top: 44px;
  right: 33px;
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-video-text {
    right: 0;
    top: 50%;
    padding-bottom: 7px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-table;
  }
}

.home-video-text:focus {
  background: #fff;
  outline: 4px solid #fff !important;
}

.home-video-ipad-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 170px;
  width: 210px;
}

.home-video-wrapper-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  border-radius: 5px;
  transform: translateX(-50%) translateY(-50%) translateY(-7px);
  height: 124px;
  width: 180px;
}

.home-navbar {
  background: #d4cdcd;
  border: 3px solid #979797;
  text-align: left;
  color: #000;
  position: relative;
  top: -4px;
  border-radius: 4px;
  padding: 7px 0 7px 5%;
}

.home-navbar-mobile {
  display: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-navbar-mobile {
    display: block;
    top: inherit;
    border-radius: 0;
    border: none;
    box-shadow: 0 4px 4px 1px rgba(31, 30, 30, 0.7);
    text-align: center;
    padding: 13px 0;
  }
}

.home-navbar-mobile img {
  height: 35px;
}

.home-navbar-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 2.5em;
  margin: 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-navbar-text {
    text-align: center;
  }
}

.home-section-video-mobile {
  flex: 1;
  position: relative;
}

.home-section-donate-mobile {
  flex: 0.25;
}

.home-section-button-mobile {
  flex: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
}

.home-section-button-row-mobile {
  flex: 1;
}

.home-section-copyright-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.45;
}

.home-options {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-options-container {
  display: flex;
  height: calc(100% - 66px);
  position: relative;
}

.home-options-row {
  display: flex;
  flex: 1;
  position: relative;
}

.home-options-wrapper {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-options-wrapper {
    display: inline-block;
  }
}

.home-options-wrapper:focus {
  background: #fff;
}

.home-options-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-options-donate {
  position: absolute;
  right: 0;
  transform: translateX(-75%);
  bottom: 0;
}

.home-options-forums-mobile {
  height: 0;
}

.home-options-forums-mobile-button {
  transform: translateY(-20%);
}

.home-options-icon {
  max-height: 80px;
  max-width: 120px;
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-options-icon {
    max-height: 8.6vh;
    max-width: 32vw;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-options-icon-image {
    max-height: 11.1vh;
    margin-bottom: -5px;
  }
}

.home-options-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  text-shadow: 1px 2px 0 rgba(82, 80, 80, 0.5);
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .home-options-text {
    font-size: 0.9em;
  }
}

.home-options-text-red {
  color: #fe3824;
}

.home-options-text-purple {
  color: #639;
}

.home-options-text-black {
  color: #21201e;
}

.home-options-text-yellow {
  color: #dfbd02;
}

.home-options-text-blue {
  color: #20344a;
}

.home-options-text-green {
  color: #39620a;
}

.home-options-text-forum {
  color: #eb6917;
}

.home-section-hm {
  position: absolute;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50%;
  right: 35px;
  z-index: 20;
}

.home-hm-gif {
  width: 100%;
}

.home-section-hm-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-hm-gif-mobile {
  width: 58%;
}

.hm-button {
  width: 100%;
}

.landing-content {
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.landing-title {
  flex: 1.3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: normal;
}

.landing-image {
  height: 16vw;
  width: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .landing-image {
    display: block;
    max-width: 70vw;
    max-height: 16vh;
    width: auto;
    height: auto;
  }
}

.landing-start {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  color: #000;
  text-shadow: 3px 3px 4px #21285d, 0 0 16px rgba(191, 191, 191, 0.56);
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.landing-start-arrow {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  color: #000;
  text-shadow: 3px 3px 4px #21285d, 0 0 16px rgba(191, 191, 191, 0.56);
  animation: blinker 1s linear infinite;
}

.landing-start-text {
  cursor: pointer;
  font-weight: normal;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .landing-start-text {
    font-size: 1em;
  }
}

.landing-copyright {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .landing-copyright {
    font-size: 0.8em;
    margin-bottom: 10vh;
  }
}

.landing-copyright-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  color: #000;
  text-shadow: 3px 3px 4px #21285d, 0 0 16px rgba(191, 191, 191, 0.56);
  font-size: 10px;
  font-size: 0.9em;
  font-weight: normal;
}

.shimmer-text-effect {
  overflow: hidden;
  position: relative;
  filter: contrast(110%) brightness(190%);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shimmer-text-effect {
    align-self: center;
  }
}

.shimmer-neon {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  position: relative;
  background: #000;
  color: rgba(0, 0, 0, 0);
  margin: 0;
  font-size: 3.6em;
}

.shimmer-neon:after,
.shimmer-neon:before {
  content: attr(data-text);
  color: #fff;
  filter: blur(0.02em);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.shimmer-neon:after {
  mix-blend-mode: difference;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shimmer-neon {
    font-size: 2.45em;
  }
}

.shimmer-gradient,
.shimmer-spotlight {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;
}

.shimmer-gradient {
  background: linear-gradient(45deg, #29a806, white);
  mix-blend-mode: multiply;
}

.shimmer-spotlight {
  animation: light 5s infinite linear;
  background: radial-gradient(circle, #ffffff, rgba(0, 0, 0, 0) 25%) 0 0/25% 25%,
    radial-gradient(circle, #ffffff, #000000 25%) 50% 50%/12.5% 12.5%;
  top: -100%;
  left: -100%;
  mix-blend-mode: color-dodge;
}

.videos-content {
  background: #000;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-content {
    height: calc(100% - 60px);
  }
}

.videos-title-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2.5%;
  flex: 0.7;
  margin-top: 10vh;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-title-container {
    padding-bottom: 0;
    margin-top: 5vh;
  }
}

.videos-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-listing {
    display: block;
  }
}

.videos-listing:last-child {
  margin-bottom: 20vh;
}

.videos-listing-button:focus {
  outline: 4px solid #fff !important;
}

.videos-listing-preview {
  border: 2px solid rgba(41, 168, 6, 0.7);
  overflow: hidden;
  opacity: 0.95;
  width: 30vw;
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-listing-preview {
    width: calc(100% - 26px);
    margin: 0 auto;
    display: block;
  }
}

.videos-listing-info {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  margin: 35px;
  text-shadow: 0 2px 1px rgba(64, 255, 220, 0.8);
  color: #29a806;
  width: 30%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-listing-info {
    width: auto;
    margin: 15px;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-listing-info > h1 {
    font-size: 1.2em;
  }
}

.videos-listing-info > h4 {
  line-height: 200%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .videos-listing-info > h4 {
    font-size: 0.9em;
  }
}

.videos-fullscreen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 3;
  background: #000;
}

.videos-fullscreen-video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.pictures-content {
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.pictures-container {
  width: 35vw;
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pictures-max-container {
  width: 50vw !important;
}

.pictures-image {
  height: auto;
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
}

.dvd-container {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .dvd-container {
    height: calc(100% - 60px);
  }
}

.dvd-video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dvd-share {
  position: absolute;
  cursor: pointer;
  transform: translateX(-50%);
  top: calc(10vh + 80px);
  left: calc(10vw + 40px);
  color: #79d50f !important;
  background: #000;
  font-size: 1.5em !important;
  border: 4px #4c4c4c solid;
  border-radius: 10px;
  padding: 10px 15px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .dvd-share {
    top: 70px;
    left: 10px;
    transform: translateX(0);
  }
}

.dvd-share:focus {
  outline: 0 !important;
}

.dvd-controls {
  position: fixed;
  left: 0;
  bottom: 12%;
  height: 100px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  transition: opacity 1.25s;
}

.dvd-seek {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.dvd-seek-bar {
  width: 50%;
  background: rgba(0, 0, 0, 0);
}

.dvd-button {
  border: #4a4a4a solid;
  background: #000;
  -webkit-border-radius: 35;
  -moz-border-radius: 35;
  border-radius: 35px;
  font-family: Arial;
  color: #79d430;
  font-size: 20px;
  padding: 10px 20px;
  text-decoration: none;
}

.dvd-button:hover {
  background: #4a4a4a;
  border: #000 solid;
  text-decoration: none;
}

.dvd-time {
  margin: 0 5px;
}

.share-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .video-js {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
}

.video-js .vjs-tech {
  position: static !important;
  max-height: 100%;
}

.vjs-control-bar {
  bottom: 13% !important;
  width: 60% !important;
  left: 20% !important;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .vjs-control-bar {
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
  }
}

.vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.modal-open {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
  z-index: 2;
  transition: opacity 0.35s ease-in-out;
}

.modal-overlay.modal-opened {
  opacity: 1;
  pointer-events: inherit;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .modal-overlay {
    height: 100%;
  }
}

.modal-modal {
  background: #1b1b1b;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.35);
  text-align: center;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  transition: 0.35s ease-in-out;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 8px;
  width: 500px;
  height: 500px;
}

.modal-modal.modal-opened {
  opacity: 1;
  pointer-events: inherit;
}

.modal-modal.modal-opened .modal-content {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .modal-modal {
    width: calc(100vw - 40px);
    height: calc(100% - 40px);
  }
}

.modal-content {
  padding: 20px 0;
  opacity: 0;
  transition: 0.35s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 56px);
  flex-direction: column;
}

.modal-close {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  margin: 0;
  text-align: left;
  font-size: 1em;
}

.modal-title {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 1.4em;
}

.modal-footer {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 0;
}

.modal-items-container {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.modal-items-container > canvas {
  width: 100%;
  height: 392px;
}

.notify-modal {
  padding: 20px 60px;
  min-width: 300px;
  max-width: 500px;
  width: auto;
  height: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .notify-modal {
    width: calc(100vw - 40px);
  }
}

.notify-message {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 1.4em;
  line-height: 1.4em;
}

.notify-confirm {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.model-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-size: cover;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .model-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.model-modal {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none !important;
}

.model-close {
  top: 25vh;
  left: 35vw;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  font-size: 2em;
}

.model-loading {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  pointer-events: none;
  font-size: 1.75em;
  opacity: 0;
}

.model-price {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  top: 75vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  pointer-events: none;
  font-size: 1.5em;
}

.model-copyright {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  top: 80vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  pointer-events: none;
}

.product-header {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  width: 88%;
  height: 60px;
  background: rgba(0, 0, 0, 0);
  margin-top: calc(5vh + 54px);
  z-index: 1;
  pointer-events: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-header {
    margin-top: 2vh;
    height: 60px;
    justify-content: center;
    width: 100%;
  }
}

.product-header-logo {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-header-logo {
    height: 60px;
    width: 60px;
  }
}

.product-header-logo img {
  pointer-events: all;
  height: 50px;
  width: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-header-logo img {
    height: 45px;
    width: auto;
  }
}

.product-header-back-button {
  pointer-events: all;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 24px;
  height: 60px;
  width: auto;
  background: rgba(0, 0, 0, 0);
  display: none;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-header-back-button {
    display: flex;
  }
}

.product-header-back-button button {
  cursor: pointer;
  padding-top: 2px;
  font-size: 32px;
  font-family: 'Press Start 2P', monospace;
}

.product-content {
  background: #000;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-content {
    height: calc(100% - 60px);
    filter: brightness(1.2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.product-container {
  height: 550px;
  width: 510px;
  margin: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-container {
    height: 100%;
    margin: 0;
    width: 100%;
  }
}

.product-type-position {
  left: 100%;
  position: relative;
  height: 0;
  width: 0;
  z-index: 2;
}

.product-type-container {
  height: 430px;
  width: 120px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.product-type-container-right {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-type-container {
    transform: none;
    padding: 2vh 0 0 5vw;
    height: 40vh;
    width: 19vw;
  }
}

.product-option-1 {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #663399;
  box-shadow: 3px 0 #639, -3px 0 #639, 0 -3px #639, 0 3px #639, 6px 0 #000,
    -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-1:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-2 {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #a78e03;
  box-shadow: 3px 0 #a78e03, -3px 0 #a78e03, 0 -3px #a78e03, 0 3px #a78e03,
    6px 0 #000, -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-2:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-3 {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #1a2d93;
  box-shadow: 3px 0 #1a2d93, -3px 0 #1a2d93, 0 -3px #1a2d93, 0 3px #1a2d93,
    6px 0 #000, -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-3:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-4 {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #a78e03;
  box-shadow: 3px 0 #a78e03, -3px 0 #a78e03, 0 -3px #a78e03, 0 3px #a78e03,
    6px 0 #000, -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-4:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-plvtform {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #1a2d93;
  box-shadow: 3px 0 #1a2d93, -3px 0 #1a2d93, 0 -3px #1a2d93, 0 3px #1a2d93,
    6px 0 #000, -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-plvtform:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-plvtform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-tstng {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  background: #a78e03;
  box-shadow: 3px 0 #a78e03, -3px 0 #a78e03, 0 -3px #a78e03, 0 3px #a78e03,
    6px 0 #000, -6px 0 #000, 0 -6px #000, 0 6px #000, 0 0 0 3px #000;
}

.product-option-tstng:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-tstng {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

.product-option-icon {
  transform: translate(-1px, -3px);
}

.product-option-cart {
  flex: 1;
  margin: 20px 0;
  opacity: 0.85;
  padding: 0 7px;
  cursor: pointer;
  transition: opacity 0.175s linear;
  z-index: 5;
  border: none;
  padding: 10px;
  z-index: 1;
}

.product-option-cart:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 75px;
    margin: 15px 0;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-cart {
    touch-action: none;
  }
}

.product-option-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  text-align: left;
  font-size: 0.6em;
  padding-top: 10px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-option-text {
    padding-top: 0;
    margin: 0;
    font-size: 0.55em;
    line-height: 2.6em;
    text-align: center;
  }
}

.product-option-active {
  opacity: 1;
}

.product-option-active > .product-option-text {
  text-shadow: 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff;
}

.product-sideimages-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #fff;
  border-radius: 4px;
  position: absolute;
  top: 50px;
  margin-left: 24px;
  padding: 1px 10px;
  z-index: 35;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-sideimages-wrapper {
    transform: translateY(-20px);
    right: 0;
    margin-left: 0;
    margin-right: 24px;
  }
}

.product-sideimages-button {
  cursor: pointer;
  margin-bottom: 15px;
}

.product-sideimages-button:first-child {
  margin-top: 15px;
}

.product-sideimages-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-sideimages-image {
    height: 30px;
    width: 30px;
  }
}

.product-items-container {
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-container {
    margin-top: 50px;
    padding: 0;
    padding-top: 2dvh;
    height: 90%;
  }
}

.product-items-message {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 0.6em;
}

.product-items-item {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: stretch;
  flex-direction: column;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-item {
    display: none;
  }
}

.product-items-item-mobile {
  min-height: 275px;
  max-height: 285px;
  display: none;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-item-mobile {
    display: inherit;
    flex: 2;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.product-items-options {
  margin-top: 0px;
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-options {
    margin-bottom: 30dvh;
  }
}

.product-items-options-top {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-options-top {
    margin: 0px;
    flex: none;
  }
}

.product-items-options-top #name-and-price {
  max-height: fit-content;
  min-width: 220px;
  max-width: 220px;
}

.product-items-options-middle {
  flex: 1;
  width: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-options-middle {
    margin: 5px 0;
    flex: none;
  }
}

.product-items-options-bottom {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-options-bottom {
    margin: 0px;
    flex: none;
  }
}

.product-items-arrow {
  visibility: hidden;
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  font-size: 2em;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  flex: 1;
  margin: 0;
  padding: 12px;
  padding-top: 0px;
  transform: translateY(-12px);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-items-arrow {
    flex: 0.5;
  }
}

.product-items-arrow button {
  cursor: pointer;
}

.product-item-image {
  max-height: 100%;
  width: auto;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.product-item-option {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  flex: 1;
  font-size: 0.8em;
  line-height: 2.1em;
  margin: 0;
  margin-bottom: 4px;
}

.product-progress-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
}

.product-size-wrapper {
  display: flex;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 8px;
}

.product-size-option {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.product-size-option-disabled {
  color: dimgray;
  cursor: auto;
}

.product-size-button {
  cursor: pointer;
  display: inline-block;
  min-width: 50px;
  margin: 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.product-cart-container {
  position: relative;
  height: 0;
  width: 0;
  margin-left: calc(100% + 80px);
  top: -40px;
  left: -40px;
}

.product-cart-image {
  width: 50px;
  height: auto;
  cursor: pointer;
  margin: auto;
  transform: translateY(-15%);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .product-cart-image {
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    left: 100vw;
    padding: 20px;
  }
}

.product-buy-button {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;
  display: inline-block;
  min-width: 50px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.product-buy-button-disabled {
  border: 1px solid dimgray;
  color: dimgray;
  cursor: inherit;
  pointer-events: none;
}

.product-glitch {
  position: relative;
}

.product-glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: #fff;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 0.6s infinite linear alternate-reverse;
  white-space: nowrap;
}

.product-glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: #fff;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 0.4s infinite linear alternate-reverse;
  white-space: nowrap;
}

.notify-me-button {
  z-index: 1;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .notify-me-button {
    max-height: 60px !important;
  }
}

#plvtform-content {
  background: #000;
}

.shop {
  background: #000;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shop * {
  margin: 0;
  padding: 0;
  font-family: 'Press Start 2P', monospace;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop {
    filter: brightness(1.2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.shop-header {
  margin-top: calc(2vh + 60px);
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  height: 100px;
  width: 88%;
  background: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop-header {
    margin-top: 2vh;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop-header {
    margin-top: 2vh;
    height: 60px;
    justify-content: center;
    width: 100%;
  }
}

.shop-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop-header-logo {
    height: 60px;
  }
}

.shop-header img {
  height: 50px;
  width: auto;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop-header img {
    height: 45px;
  }
}

.shop-container {
  max-height: 95dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-container-grid {
  max-width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: calc(20px + 2vw);
  justify-content: center;
  margin-bottom: 250px;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .shop-container-grid {
    width: 100%;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 100px;
  }
}

.shop-container-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 90%;
  margin-top: 24px;
  margin-bottom: 12px;
  background: #000;
  cursor: pointer;
}

.shop-container-grid-item .item-price {
  position: relative;
  transform: translate(0%, -2px);
  color: #fff;
  font-size: 0.65em;
}

.shop-container-grid-item img {
  height: 180px;
  width: auto;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.shop-container-grid-item .item-title {
  min-height: 32px;
  max-width: calc(9vh + 110px);
  color: #fff;
  font-size: 0.65em;
  text-align: center;
  line-height: 160%;
}

.shop-container-grid-item button {
  margin-top: 10px;
  padding: 5px 10px;
  background: #000;
  color: #fff;
  font-size: 0.65em;
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.shop-container-grid-item .button-disabled {
  border: 1px solid dimgray;
  color: dimgray;
  cursor: inherit;
  pointer-events: none;
}

.terms-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.terms-wrapper {
  margin: 10vh 20vw;
}

@media screen and (max-width: 480px) and (orientation: portrait),
  screen and (max-height: 480px) and (orientation: landscape) {
  .terms-wrapper {
    margin: 0;
  }
}

.terms-text {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: #fff;
  color: #fff;
  line-height: 1.3em;
}

/*# sourceMappingURL=main.min.css.map */
