@function multiple-box-shadow($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }
  @return unquote($value);
}
@mixin no-select {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
@mixin press-start {
  font-family: 'Press Start 2P', monospace;
  font-weight: normal;
  font-size: 1em;
  color: white;
}
@mixin centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin absolute-center($top, $left) {
  top: $top;
  left: $left;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}
@mixin mobile {
  @media screen and (max-width: $mobile-max-w) and (orientation: portrait),
    screen and (max-height: $mobile-max-w) and (orientation: landscape) {
    @content;
  }
}
